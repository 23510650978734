.nav ul {
    border-bottom: 0 !important;
}
.nav-link {
    font-weight: bold;
    border-radius: 0 !important;
    color: black;
}
.nav-link:hover {
    cursor: pointer;
    background-color: #d8ddef;
    color: black;
}
.nav-link.active {
    background-color: #4d6d9a !important;
}

.views {
    padding-inline-end: 5%;
}

.badge-secondary {
    background-color: rgb(170, 169, 169);
}
