@import 'bootstrap/scss/functions';

$accordion-bg: transparent;
$accordion-button-color: #4d6d9a;
$accordion-button-active-bg: #4d6d9a !important;
$accordion-button-active-color: white;
$btn-link-color: #4d6d9a;

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/buttons';

.App {
    background-color: ghostwhite;
    color: #282c34;
    border-radius: 6px;
    margin: auto;
    margin-bottom: 1.5%;
    margin-top: 1.5%;
}

.App-header {
    text-align: left;
    align-items: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #86b3d1;
}

a,
a:hover {
    color: #4d6d9a;
}

.accordion-button {
    font-weight: bold;
}
